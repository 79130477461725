import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Context from "../context/Context";

const API_URL = process.env.REACT_APP_API_URL;

class Dashboard extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            filteredData: null,
            modal: false,
            id: null,
            name: null,
            address: null,
            additionalAddress: null,
            city: null,
            phone: null
        };
    }

    async componentDidMount() {
        const { service } = await this.context.globalstate;
        const { data } = await service.request.get(`${API_URL}/jobs`);
        this.setState({ data: data, filteredData: data });
        console.log(this.state.data);
    }

    showAll = () => {
        this.setState({ filteredData: this.state.data });
    };
    filterByFailed = () => {
        if (this.state.data) {
            let result = { jobs: this.state.data.jobs.filter(job => job.tbc_result === "FAILED") };
            console.log('result::', result);
            this.setState({ filteredData: result });
        }
    };

    filterByOk = () => {
        if (this.state.data) {
            let result = { jobs: this.state.data.jobs.filter(job => job.tbc_result === "OK") };
            console.log('result::', result);
            this.setState({ filteredData: result });
        }
    };

    toggle = () => {
        let currentState = this.state.modal;
        this.setState({ modal: !currentState });
    };

    prepareOrder = (id, name, address, additionalAddress, city, phone) => {
        this.setState({
            id: id,
            name: name,
            address: address,
            additionalAddress: additionalAddress,
            city: city,
            phone: phone
        });
    };

    postOrder = async () => {
        console.log(this.state.id, this.state.name, this.state.address, this.state.additionalAddress, this.state.city, this.state.phone);
        const { service } = await this.context.globalstate;
        const response = await service.request.post(`https://online.translate.ge/api/admin/posteService`, {
            id: this.state.id,
            name: this.state.name,
            address: this.state.address,
            additionalAddress: this.state.additionalAddress,
            city: this.state.city,
            phone: this.state.phone
        });

        if (response.data.status === 'fail') alert('კურიერის გამოძახება ვერ მოხერხდა, სცადეთ თავიდან');
        console.log(response);
    };


    render() {
        return (
            <div className="job-table-container">
                <h1 className="text-center">გადახდების ცხრილი</h1>
                <button onClick={this.showAll} className="filter-btn">მაჩვენე ყველა</button>
                <button onClick={this.filterByOk} className="filter-btn">მაჩვენე მხოლოდ გადახდილები</button>
                <button onClick={this.filterByFailed} className="filter-btn">მაჩვენე მხოლოდ გადაუხდელები</button>
                {this.state.data ? (
                    <table className="job-table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>სახელი</th>
                            <th>ინფორმაცია ფაილის შესახებ</th>
                            <th>საკონტაქტო ინფორმაცია</th>
                            <th>კომენტარი</th>
                            <th>ფასი</th>
                            <th>გადახდის სტატუსი</th>
                            <th>შეკვეთის თარიღი</th>
                            <th>შეკვეთის ნომერი</th>
                            <th>საკურიერო სერვისი</th>
                        </tr>
                        </thead>
                        <tbody className="table-hover">
                        {this.state.filteredData.jobs.map((job, index) => (
                            <React.Fragment>
                                <tr>
                                    <th>{index + 1}</th>
                                    <td>{job.project.name}</td>
                                    <td>
                                        <ul>
                                            <li>ფაილის სახელი: {job.project.fileNames}</li>
                                            {/* {job.project._countResponse.fileLink ? <li>{job.project._countResponse.fileLink}</li>:null} */}
                                            {job.project._countResponse && job.project._countResponse.fileLink &&
                                            <li><a href={job.project._countResponse.fileLink} rel="noopener noreferrer"
                                                   target="_blank">ფაილის ბმული</a></li>}
                                            {job.project.data ? (
                                                <>
                                                    <li>
                                                        თარგმნა: {job.project.data.baseLanguage} ->
                                                        {job.project.data.targetLanguage}
                                                    </li>
                                                    <li>
                                                        მიწოდების დრო:
                                                        {job.project.data.passingDate === "slow"
                                                            ? " სტანდარტული"
                                                            : " დაჩქარებული"}
                                                    </li>
                                                    <li>
                                                        ნოტარიუსი:
                                                        {job.project.data.additionalService === "false"
                                                            ? " არ სურს"
                                                            : " სურს"}
                                                    </li>
                                                    {job.project.data.courierService && job.project.data.courierService === 'true' ? (
                                                        <>
                                                            <li>საკურიერო სერვისი: სურს</li>
                                                        </>
                                                    ) : <li>საკურიერო სერვისი: არ სურს</li>
                                                    }
                                                </>
                                            ) : null}
                                        </ul>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>ელ-ფოსტა: {job.project.email}</li>
                                            <li>ტელ: {job.project.phone}</li>
                                            <li>
                                                მისამართი: {job.project.address || "არ მიუთითებია"}
                                                {job.project.additionalAddress ? job.project.additionalAddress : ''}
                                            </li>
                                            {job.project.data && job.project.data.courierService ? (
                                                <>
                                                    {job.project.data.courierService === 'true' ?
                                                        <li>
                                                            ქალაქი: {job.project.city || "არ მიუთითებია"}
                                                        </li> : <li>ქალაქი: არ მიუთითებია</li>
                                                    }
                                                </>
                                            ) : <li>ქალაქი: არ მიუთითებია</li>}
                                        </ul>
                                    </td>
                                    <td>{job.project.comment}</td>
                                    <td>{job.payment_amount}</td>
                                    <td>{job.tbc_result}</td>
                                    <td>{job.updated_at.split('T').join('\n')}</td>
                                    <td>#{job.id}</td>
                                    <td>
                                        {job.project.data && job.project.data.courierService ? (
                                            <>
                                                {job.project.data.courierService === 'true' && job.project.city ?
                                                    <>
                                                        {job.hasorderedposte === null ?
                                                            <button className={"order-btn"}
                                                                    onClick={() => {
                                                                        this.toggle();
                                                                        this.prepareOrder(job.id, job.project.name, job.project.address, job.project.additionalAddress, job.project.city, job.project.phone);
                                                                    }}>
                                                                კურიერის გამოძახება
                                                            </button> :
                                                            <p className={"courier-info"}>მოთხოვნა გაგზავნილია
                                                                კურიერთან</p>
                                                        }
                                                    </>
                                                    : null
                                                    // <p className={"courier-info"}>საკურიერო სერვისი არ სურს</p>
                                                }
                                            </>
                                        ) : null
                                            // <p className={"courier-info"}>საკურიერო სერვისი: არ სურს</p>
                                            // <button className={"order-btn"}
                                            //         onClick={() => {
                                            //             this.toggle();
                                            //             this.prepareOrder(job.id, job.project.name, job.project.address, job.project.additionalAddress, job.project.city, job.project.phone);
                                            //         }}>
                                            //     კურიერის გამოძახება
                                            // </button>
                                        }
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                        <Modal
                            centered={true}
                            isOpen={this.state.modal}
                            toggle={this.toggle}>
                            <ModalHeader toggle={this.toggle}>საკურიერო სერვისი</ModalHeader>
                            <ModalBody>
                                ნამდვილად გსურთ კურიერის გამოძახება?
                            </ModalBody>
                            <ModalFooter>
                                <Button color="success" onClick={() => {
                                    this.toggle();
                                    this.postOrder();
                                }}>დიახ</Button>{' '}
                                <Button color="danger" onClick={this.toggle}>არა</Button>
                            </ModalFooter>
                        </Modal>
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center mt-4">გთხოვთ დაელოდოთ...</p>
                )}
            </div>
        );
    }
}

export default Dashboard;
