import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom"
import { ContextWrapper } from "../context/ContextWrapper";
import { PrivateRoute } from './PrivateRoute';
import Login from "./Login/Login";
import Dashboard from "./Dashboard";


class Layout extends Component {
	
	constructor(props) {
		super(props);
		this.state = {}
		this.redirect = this.redirect.bind(this);
	}

	redirect = (name) => {
		this.props.history.push(name);
	}

	render() {
		const { provider } = this.props;
		return (
			<div>
				
				
				<main role="main" className="flex-shrink-0">
					<Switch>

						<PrivateRoute path="/" exact={true} component={Dashboard} />
					
						<Route path="/login" 
							render={ (context) =>  
								<Login context={context} provider={provider} />  }
						/>
						{/* <Route path="/register" component={Register} /> */}
					</Switch>
				</main>
			</div>
		);
	}
}

export default withRouter(ContextWrapper(Layout));
