import React, { Component } from 'react';
import Layout from './pages/Layout';
import 'bootstrap/dist/css/bootstrap.css';


class App extends Component {
	render() {
		return (
			<Layout />
		);
	}
}

export default App;
