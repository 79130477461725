import React, { Component } from "react";
import Context from "./Context";
import axios from "axios"
import Service from './WrappedAxious';
import _ from "lodash";

const API_URL = process.env.REACT_APP_API_URL;


class Provider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            service : new Service(),
            jwtToken : null,
            isLoggedIn : false,
        };
    }


    setJwtToken = async (token) => {
        this.setState({ jwtToken : token });
        this.setState({ service : new Service(token) });
        this.setState({ isLoggedIn : true });
    }

    logout = () => {
        this.setState({ jwtToken : null });
        this.setState({ isLoggedIn : false });
    }

    login = async (username, password) => {
        const loginData = {
                "username" : username,
                "password" : password
            }

        const response = await 
            axios.post(`${API_URL}/login`, loginData)
                .catch(function (error) { 
                    console.log(error)    
        });
        if(response) {
            await this.setJwtToken(response.data.token)
        }
        return response;
    }

    async componentDidMount() { }

    render() {
        const handlers = {
            login : this.login,
            logout : this.logout
        }
        return (
            <Context.Provider value={{ globalstate : this.state, handlers : handlers}}>
                {this.props.children}
            </Context.Provider>
        );
    }
}

export default Provider;
