import React, {Component} from 'react';
import Context from '../../context/Context';
import { Button, Input } from 'reactstrap';

class Login extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            loginError : false,
            submitProccess : false,
            username : process.env.REACT_APP_ADMIN_USER,
            password : process.env.REACT_APP_ADMIN_PASSWORD
        }
    }

    handleChange = async (e) => {
        this.setState({ [e.target.name] : e.target.value });
    }

    login = async () => {
        this.setState({ submitProccess : true });
        const { login } = this.context.handlers;
        await login(this.state.username, this.state.password);
        // To Do :  Check Login response ... or Show Error Message
        this.setState({ submitProccess : false });
        this.props.context.history.push("/");
    }

    handleKeyDown = async (e) => {
        if (e.key === 'Enter' && e.target.name === 'password') {
            console.log('Do Login !!! ', e.target.name);
            await this.login();
        }
    }

    goToregister = () => {
        console.log('Go to : ', this.props)
        this.props.context.history.push("/register");
    }

    componentDidMount () {
    }

    render() {
        const { submitProccess } = this.state;
        return (
            <div>
                <section className="mt-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 overlay">
                                <div className="card py-8 px-6 text-center shadow-light-lg">
                                    <div className="card-body p-0">


                                        <div className="mb-3">
                                            <h2 className="font-weight-bold">online.translate.ge ადმინ პანელი</h2>
                                            <p>გასაგრძელებლად გაიარეთ ავტორიზაცია</p>
                                        </div>
                                        <div className="card-register-inner">


                                            <Input autoFocus disabled={submitProccess}
                                                name="username"
                                                onKeyDown={this.handleKeyDown}
                                                onChange={this.handleChange}
                                                value={this.state.username}
                                                type="email"
                                                className="form-control mb-2"
                                                placeholder="Username"
                                            />
                                            <Input name="password"  disabled={submitProccess}
                                                onKeyDown={this.handleKeyDown}
                                                onChange={this.handleChange}
                                                value={this.state.password}
                                                type="Password"
                                                className="form-control mb-2"
                                                placeholder="Password" />

                                            {/* <p><a href={"#forgotpassword"}>Forgot your password?</a></p> */}

                                            <Button disabled={submitProccess} onClick={this.login}
                                                    className="btn btn-green-gradient d-block w-100 mb-4">
                                                    {submitProccess &&
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                                                            &nbsp;
                                                        </span>
                                                    }
                                                    {!submitProccess  &&
                                                        <span>შესვლა</span>
                                                    }
                                            </Button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div className="py-6"></div>
                </div>
            </section>
        </div>
        );
    }
}

export default Login;