import React from "react";
import { Route, Redirect } from "react-router-dom";
import Consumer from '../context/Consumer';

export const PrivateRoute = ({ component: Component, ...rest }) => (
	<Consumer>
	{(provider) => {
		return (<Route {...rest} render={(props) => (provider.globalstate.isLoggedIn ? 
			<Component {...props} /> : <Redirect to='/login' />)} />);
	}}
</Consumer>);
